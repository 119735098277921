export const contactsData = {
    email: 'alvaroam2000@gmail.com',
    phone: '+34 615 35 77 53',
    address: 'Madrid ',
    //github: 'https://github.com/said7388',
    //facebook: 'https://www.facebook.com/abusaid.riyaz/',
    linkedIn: 'https://www.linkedin.com/in/arenzanaa/',
    //twitter: 'https://twitter.com/said7388',
    //medium: 'https://medium.com/@abusaid-bd',
    //stackOverflow: 'https://stackoverflow.com/users/16840768/abu-said',
    //devUsername: "said7388"
}