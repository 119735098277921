export const experienceData = [
    {
        id: 1,
        company: 'ionIDe Telematics SL',
        jobtitle: 'Software Engineer',
        startYear: 'Nov 2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Self-employed',
        jobtitle: 'Web design and programming',
        startYear: 'Jun 2017',
        endYear: 'Nov 2021'
    },
    {
        id: 3,
        company: 'IEEE Globecom Madrid',
        jobtitle: 'Volunteer',
        startYear: 'Dec 2021'
    },
    {
        id: 4,
        company: 'Valvanera C.D.',
        jobtitle: 'Coach and instructor',
        startYear: 'Sep 2013',
        endYear: 'Jun 2018'
    },
]