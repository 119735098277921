import image from '../assets/png/alvaro_rounded.png'
import imagebw from '../assets/png/alvaro_rounded_bw.png'
import pdf from '../assets/pdf/AlvaroArenzanaEnglish.pdf'

export const headerData = {
    name: 'Álvaro Arenzana',
    title: "Hello! I am",
    desciption: "Hello! I am Álvaro Arenzana, a professional and passionate Telecommunications Engineer. I love to learn about mobile devices and offer my knowledge and passion about telecommunications and cybersecurity. ",
    image: image,
    imagebw: imagebw,
    resumePdf: pdf
}
