export const educationData = [
    {
        id: 1,
        institution: 'Carlos III University of Madrid',
        course: 'Double Masters Degree on Telecommunications and Cybersecurity',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Carlos III University of Madrid',
        course: 'Bachelors Degree on Telecommunications Technologies',
        startYear: '2018',
        endYear: '2022'
    },
    {
        id: 3,
        institution: 'Cosme García Institute of Logroño',
        course: 'Secondary School',
        startYear: '2012',
        endYear: '2018'
    },
]